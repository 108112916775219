import { capitalizeEachWord } from '@dmm/lib-common/lib/formatting';
import capitalize from 'lodash/capitalize';
import endsWith from 'lodash/endsWith';
import filter from 'lodash/filter';
import first from 'lodash/first';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import memoize from 'lodash/memoize';
import reject from 'lodash/reject';
import size from 'lodash/size';
import startsWith from 'lodash/startsWith';
import { ENGINES_ID, INDEXABLE_ENGINES_FACETS } from '../constants/Engines';
import { EXTENDED_SERVICES_PLAN_META_DESCRIPTION, EXTENDED_SERVICES_PLAN_META_TITLE, GAP_PROTECTION_PLAN_META_DESCRIPTION, GAP_PROTECTION_PLAN_META_TITLE, ROAD_ASSISTANCE_PLAN_META_DESCRIPTION, ROAD_ASSISTANCE_PLAN_META_TITLE, TIRE_AND_WHEEL_PLAN_META_DESCRIPTION, TIRE_AND_WHEEL_PLAN_META_TITLE } from '../constants/TridentServices';
import { BRANDS_ID, INDEXABLE_FACETS, INDEXABLE_FUELS, SEARCH_MAX_BOATS } from '../constants/boats';
import { META_DESCRIPTION as HOME_META_DESCRIPTION, META_TITLE as HOME_META_TITLE } from '../constants/home';
import { default as resourceConfigs } from '../containers/Services/OtherServices/configs.js';
import { handleLegacyAllType } from './classHelper';
import { getClass, getStateByCode, getType } from './commonHelper';
import { getSubdivisionCode, urlFromDealerFilters } from './dealerSRPHelper';
import { getEnginesSRPHeader, getEnginesSRPMetaDescription } from './engineTypeHelper';
import { getLocation, getTitle, isFSBOContact } from './listingHelper';
import { isSingleFaceted } from './multiFacetHelper';
import multiParamMemoizeResolver from './multiParamMemoizeResolver';
import { getBoatUrl } from './urlHelpers/boat';
import {
  getDealerSearchUrl,
  getDealersSRPHeader,
  urlParamToFormattedString
} from './urlHelpers/boatDealers';
import {
  generateSearchPath,
  getActiveParams,
  getDefaultParams,
  parseSearchAppParams,
  unhyphenateUrlComponents,
  unslugify
} from './urlHelpers/listings';

export const getMetaTitle = (pageType, params, searchCount, boatData, makeModel, partyDetails, oemDetails) => {
  switch (pageType) {
  case 'BoatDetails':
    return getBDPMetaTitle(boatData);
  case 'SearchResults':
  case 'DealerGallery':
  case 'BoatDealers':
  case ENGINES_ID:
    return getSRPMetaTitle(params, searchCount, makeModel, partyDetails, pageType);
  case 'Services':
    return 'Boat Services: Boat Loans, Insurance, Transport, And More - BoatTrader.com';
  case 'BoatLoans':
    return 'Boat Loans';
  case 'BoatLoansV2':
    return 'Boat Loans | Boat Trader';
  case 'BoatLoansRates':
    return 'Boat Loans Rates | Boat Trader';
  case 'FinanceServices':
    return getServiceMetaTitle('Boat Loans');
  case 'ShippingServices':
    return getServiceMetaTitle('Boat Transport');
  case 'InsuranceServices':
    return getServiceMetaTitle('Boat Insurance');
  case 'WarrantyServices':
    return getServiceMetaTitle('Boat Warranty');
  case 'DocumentationServices':
    return getServiceMetaTitle('Boat Documentation');
  case 'RentalServices':
    return getServiceMetaTitle('Boat Rental');
  case 'TridentExtendedServicePlan':
    return getServiceMetaTitle(EXTENDED_SERVICES_PLAN_META_TITLE);
  case 'TridentTireWheelProtection':
    return getServiceMetaTitle(TIRE_AND_WHEEL_PLAN_META_TITLE);
  case 'TridentServicesRoadsideAssistancePlan':
    return getServiceMetaTitle(ROAD_ASSISTANCE_PLAN_META_TITLE);
  case 'TridentServicesGAPProtectionPlan':
    return getServiceMetaTitle(GAP_PROTECTION_PLAN_META_TITLE);
  case 'DrivewayDirect':
    return getServiceMetaTitle('Get a Cash Offer for Your Boat');
  case 'BoatHistory':
    return getServiceMetaTitle('Boat History Report');
  case 'BadCreditBoats':
  case 'BoatAuctions':
  case 'BoatCovers':
  case 'BoatDonation':
  case 'BoatLettering':
  case 'BoatLifts':
  case 'BusinessOpportunities':
  case 'DealerResources':
  case 'Docks':
  case 'ExtendedServiceContracts':
  case 'FactorySpecials':
  case 'InternationalBoatExport':
  case 'MarineElectronics':
  case 'MarineInteriors':
  case 'WaterfrontProperty':
  case 'DealerCertification':
  case 'IndustryIdeas':
  case 'MarineSupplies':
  case 'NewBoatOwnerProgram':
  case 'Repower':
    return getServiceMetaTitle(resourceConfigs[pageType].title);
  case 'Home':
    return HOME_META_TITLE;
  case 'BoatClass':
    return getBoatClassMetaTitle(params?.displayName);
  case 'OemSearchResults':
    return getOemSearchResultsMetaTitle(oemDetails);
  default:
    return 'Boat Trader';
  }
};

/* eslint-disable max-len */
export const getMetaDescription = (pageType, params, searchCount, boatData, makeModel, partyDetails, pageId, oemDetails, isSimilarBoatsPage) => {
  switch (pageType) {
  case 'BoatDetails':
    return getBDPMetaDescription(boatData);
  case 'SearchResults':
    return getSRPMetaDescription(params, searchCount, makeModel, partyDetails, pageId, isSimilarBoatsPage);
  case 'DealerGallery':
    return getSRPMetaDescription(params, searchCount, makeModel, partyDetails, pageId);
  case 'BoatDealers':
    return getDealerSRPMetaDescription(params, searchCount);
  case ENGINES_ID:
    return getEnginesSRPMetaDescription(params, searchCount);
  case 'Services':
    return 'Boating services from finance to insurance, boat history reports, surveyors, shipping and more. All you need to help get out on the water';
  case 'FinanceServices':
    return 'Get pre-approved and take a big step toward buying your new boat. Boat Trader can help you find participating boat lenders in every state.';
  case 'BoatLoans':
    return 'Unlock your water-bound dreams with affordable boat loans today! Dive into our flexible options for new or used vessels. Set sail with us!';
  case 'BoatLoansV2':
    return 'New and used boat loans with low monthly payments, competitive rates and nothing to pay for up to 60 days. Find out more TODAY!';
  case 'BoatLoansRates':
    return 'Boat Loans Rates';
  case 'ShippingServices':
    return 'When it comes to shipping your boat, you want the safest transport at the best rate. Boat Trader can help you find reliable transporters and helpful tips for preparing your boat for transport.';
  case 'InsuranceServices':
    return 'Enjoy peace of mind as you navigate your boat or personal watercraft. Whether you\'re looking for a new boat insurance policy or just want to save money on an existing policy, Boat Trader can help you get started with a free boat insurance quote.';
  case 'WarrantyServices':
    return 'Extended marine warranty packages help boat owners eliminate excessive, out-of-pocket expenses from unforeseen breakdowns and damaged products. Boat Trader can help you find accessible and affordable marine warranty coverages.';
  case 'DocumentationServices':
    return 'Buying or selling a boat requires some amount of paperwork and documentation, ranging from a boat title and proof of ownership to a boat bill of sale agreement and other similar related contracts, documents and certificates.';
  case 'RentalServices':
    return 'Click&Boat makes boat rentals easy, whether clients want to rent a pontoon boat, party boat or fishing boat. The boat rental platform makes it easy to become an owner, create your listing, and rent out your boat.';
  case 'TridentExtendedServicePlan':
    return EXTENDED_SERVICES_PLAN_META_DESCRIPTION;
  case 'TridentTireWheelProtection':
    return TIRE_AND_WHEEL_PLAN_META_DESCRIPTION;
  case 'TridentServicesGAPProtectionPlan':
    return GAP_PROTECTION_PLAN_META_DESCRIPTION;
  case 'TridentServicesRoadsideAssistancePlan':
    return ROAD_ASSISTANCE_PLAN_META_DESCRIPTION;
  case 'DrivewayDirect':
    return 'Get an offer directly from your local dealer! With Boat Trader’s strong network of local dealers, you’ll sell your boat fast.';
  case 'BoatHistory':
    return 'BOAT HISTORY REPORT on Boat Trader.';
  case 'BadCreditBoats':
  case 'BoatAuctions':
  case 'BoatCovers':
  case 'BoatDonation':
  case 'BoatLettering':
  case 'BoatLifts':
  case 'BusinessOpportunities':
  case 'DealerResources':
  case 'Docks':
  case 'ExtendedServiceContracts':
  case 'FactorySpecials':
  case 'InternationalBoatExport':
  case 'MarineElectronics':
  case 'MarineInteriors':
  case 'WaterfrontProperty':
  case 'DealerCertification':
  case 'IndustryIdeas':
  case 'MarineSupplies':
  case 'NewBoatOwnerProgram':
  case 'Repower':
    return getOtherServiceMetaDescription(pageType);
  case 'Home':
    return HOME_META_DESCRIPTION;
  case 'BoatClass':
    return getBoatClassMetaDescription(params?.displayName);
  case 'OemSearchResults':
    return getOemSearchResultsMetaDescription(oemDetails);
  default:
    return 'Boat Trader';
  }
};

export const getSRPHeader = (params, makeModel, partyDetails) => {
  return getSRPHeaderAndMetaTitleCommon(params, false, makeModel, partyDetails);
};

export const getSEOH2Header = (params, makeModel) => {
  return getSRPHeaderAndMetaTitleCommon(params, true, makeModel);
};

export const getCanonical = (pageType, params, boatData, url) => {
  switch (pageType) {
  case 'BoatDetails':
    return getBDPCanonical(boatData);
  case 'SearchResults':
  case 'DealerGallery':
    return getSRPCanonical(params);
  case 'BoatDealers':
    return getDealerSRPCanonical(params);
  case 'Engines':
  case ENGINES_ID:
    return getEnginesSRPCanonical(params);
  case 'Services':
    return process.env.REACT_APP_HOST + '/services/';
  case 'InsuranceServices':
    return process.env.REACT_APP_HOST + '/services/boat-insurance/';
  case 'ShippingServices':
    return process.env.REACT_APP_HOST + '/services/boat-transport-shipping/';
  case 'FinanceServices':
  case 'BoatLoans':
    return process.env.REACT_APP_HOST + '/boat-loans/';
  case 'BoatLoansV2':
    return process.env.REACT_APP_HOST + '/boat-loans/';
  case 'BoatLoansRates':
    return process.env.REACT_APP_HOST + '/boat-loans/rates/';
  case 'WarrantyServices':
    return process.env.REACT_APP_HOST + '/services/boat-warranty/';
  case 'DocumentationServices':
    return process.env.REACT_APP_HOST + '/services/boat-documentation/';
  case 'RentalServices':
    return process.env.REACT_APP_HOST + '/services/boat-rental/';
  case 'TridentExtendedServicePlan':
    return process.env.REACT_APP_HOST + '/services/extended-service-plan/';
  case 'TridentTireWheelProtection':
    return process.env.REACT_APP_HOST + '/services/tire-wheel-protection/';
  case 'TridentServicesGAPProtectionPlan':
    return process.env.REACT_APP_HOST + '/services/gap-protection/';
  case 'TridentServicesRoadsideAssistancePlan':
    return process.env.REACT_APP_HOST + '/services/roadside-assistance/';
  case 'DrivewayDirect':
    return process.env.REACT_APP_HOST + '/driveway-direct/';
  case 'BoatHistory':
    return process.env.REACT_APP_HOST + '/services/boat-history';
  case 'BadCreditBoats':
  case 'BoatAuctions':
  case 'BoatCovers':
  case 'BoatDonation':
  case 'BoatLettering':
  case 'BoatLifts':
  case 'BusinessOpportunities':
  case 'DealerResources':
  case 'Docks':
  case 'ExtendedServiceContracts':
  case 'FactorySpecials':
  case 'InternationalBoatExport':
  case 'MarineElectronics':
  case 'MarineInteriors':
  case 'WaterfrontProperty':
  case 'DealerCertification':
  case 'IndustryIdeas':
  case 'MarineSupplies':
  case 'NewBoatOwnerProgram':
  case 'Repower':
    return getOtherServiceURL(pageType);
  case 'BoatClass':
    return getBoatClassCanonical(params);
  case 'OemSearchResults':
    return `${process.env.REACT_APP_HOST}/${url}`;
  default:
    return process.env.REACT_APP_HOST;
  }
};

export const getPageTypeFromUrl = (url) => {
  const pageTypes = [
    { prefix: '/boat-dealers/', type: 'BoatDealers' },
    { prefix: '/boats/', type: 'SearchResults' },
    { prefix: '/driveway-direct/', type: 'DrivewayDirect' },
    { prefix: '/brands/', type: BRANDS_ID },
    { prefix: '/engines/', type: ENGINES_ID },
  ];

  for (const { prefix, type } of pageTypes) {
    if (startsWith(url, prefix) || endsWith(url, prefix.trimEnd('/'))) {
      return type;
    }
  }
};

export const getNoIndex = (url) => {
  const pageType = getPageTypeFromUrl(url);
  switch (pageType) {
  case 'SearchResults':
  case BRANDS_ID:
  case ENGINES_ID:
    return getSRPNoIndex(url, pageType);
  default:
    return false;
  }
};

export const getNoFollow = (url) => {
  const pageType = getPageTypeFromUrl(url);
  switch (pageType) {
  case 'SearchResults':
    return getSRPNoFollow(url, pageType);
  case ENGINES_ID:
    return getSRPNoFollow(url, pageType);
  default:
    return false;
  }
};

export const getLinkRelBoatDealers = (params, increment) => {
  const linkParams = { ...params };
  if (params.countrySubDivisionCode)  {
    linkParams.state = params.countrySubDivisionCode;
  }
  const page = +params.page;
  if (!isNaN(page)) {
    const pageNum = page + increment;
    const pagePath = pageNum > 1 ? `page-${pageNum}/` : '';
    const returnUrl = process.env.REACT_APP_HOST + urlFromDealerFilters(linkParams) + pagePath;
    return returnUrl;
  }

  return '';
};

export const getLinkRelEnginesSRP = (params, increment) => {
  const linkParams = { ...params };
  const page = +params.page;
  if (!isNaN(page)) {
    const pageNum = page + increment;
    linkParams.page = pageNum;
    const defaultParams = getDefaultParams(params, ENGINES_ID);
    const returnUrl = process.env.REACT_APP_HOST + generateSearchPath(linkParams, defaultParams, false, ENGINES_ID);
    return returnUrl;
  }

  return '';
};

export const getLinkRelPrev = (params, pageType = '') => {
  if (pageType === 'BoatDealers') {
    return params.page && params.page !== '1' ? getLinkRelBoatDealers(params, -1) : '';
  }
  if (pageType === ENGINES_ID) {
    return params.page && params.page !== '1' ? getLinkRelEnginesSRP(params, -1) : '';
  }
  pageType = pageType.toLowerCase();
  const defaultParams = getDefaultParams(params, pageType);

  if (defaultParams.page && defaultParams.page !== '1' && !params.ownerId) {
    const urlParams = { page: parseInt(defaultParams.page) - 1 };
    const returnUrl = process.env.REACT_APP_HOST + generateSearchPath(urlParams, defaultParams, false, pageType);
    return returnUrl;
  }

  return '';
};

export const getLinkRelNext = (params, searchCount, pageType = '') => {
  if (pageType === 'BoatDealers') {
    const isLastPage = +params.page * params.pageSize + params.pageSize > searchCount;
    return isLastPage ? '' : getLinkRelBoatDealers(params, 1);
  }
  if (pageType === ENGINES_ID) {
    const isLastEnginesPage = +params.page * params.pageSize >= searchCount;
    return isLastEnginesPage ? '' : getLinkRelEnginesSRP(params, 1);
  }
  pageType = pageType.toLowerCase();
  const defaultParams = getDefaultParams(params, pageType);
  if ((parseInt(defaultParams.pageSize * defaultParams.page) + parseInt(defaultParams.pageSize) > SEARCH_MAX_BOATS) || params.ownerId){
    return '';
  }
  if (parseInt(defaultParams.pageSize * defaultParams.page) < searchCount) {
    const urlParams = { page: parseInt(defaultParams.page) + 1 };
    const returnUrl = process.env.REACT_APP_HOST + generateSearchPath(urlParams, defaultParams, false, pageType);
    return returnUrl;
  }
  return '';

};

const getBDPMetaDescription = (boatData) => {
  let returnDescription = 'Check out this ';
  let listingCondition;
  let listingCategory;
  let listingType;
  const listingTitle = getTitle(boatData).trim();
  const listingLocation = getLocation(boatData);

  if (boatData.id){
    listingCondition = capitalizeEachWord(boatData.condition);
    listingCategory = get(getClass(boatData.class), 'name');
    listingType = get(getType(boatData.type), 'name');
  }

  returnDescription += listingCondition;

  if (!isEmpty(listingTitle)){
    returnDescription += ' ' + listingTitle + ' for sale';
  }

  if (listingLocation){
    returnDescription += ' in ' + listingLocation;
  }

  if (listingCategory){
    returnDescription += '. View this ' + listingCategory + ' and other ' + listingType + ' boats on boattrader.com';
  } else {
    returnDescription += '. View other ' + listingType + ' boats on boattrader.com';
  }

  return returnDescription;
};

const textDealersSRPDescription = () => {
  return {
    make: 'Find :make boat dealers near you by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makecountrySubDivisionCode: 'Find :make boat dealers in :countrySubDivisionCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makecountrySubDivisionCodecity: 'Find :make boat dealers in :city by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makecountrySubDivisionCodecitypostalCode: 'Find :make boat dealers in :city, :postalCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    countrySubDivisionCode: 'Find boat dealers in :countrySubDivisionCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    countrySubDivisionCodecity: 'Find boat dealers in :city, :countrySubDivisionCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    countrySubDivisionCodecitypostalCode: 'Find boat dealers in :city, :postalCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makestate: 'Find :make boat dealers in :state by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    state: 'Find boat dealers in :state by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    statecity: 'Find boat dealers in :city, :state by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makestatecity: 'Find :make boat dealers in :city by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
    makestatecitypostalCode: 'Find :make boat dealers in :city, :postalCode by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.',
  };
};

export const getDealerSRPMetaDescription = (params, searchCount) => {
  const { page, pageSize } = params;
  const descriptionTexts = textDealersSRPDescription();
  let paginationText = getPaginationText(page, pageSize, searchCount);
  let headerFilterToFormat = ['make', 'city'];
  if (params.seoMakeInfo && params.seoMakeInfo.seoMakeName) {
    params.make = params.seoMakeInfo.seoMakeName;
    headerFilterToFormat.shift();
  }
  const activeFilter = Object.keys(params).filter(
    key => params[key] && ['make','countrySubDivisionCode','state', 'city','postalCode'].includes(key)
  );
  const activeFiltersName = activeFilter.join('');
  let metaDescription = activeFiltersName ?
    descriptionTexts[activeFilter.join('')]
    :
    'Find boat dealers near you by name, brand, location as well as boats at your local dealership. Get in contact about the boats, services & company.';

  Object.entries(activeFilter).forEach( key => {
    const prop = key[1];
    let value = headerFilterToFormat.includes(prop) ? urlParamToFormattedString(params[prop]) : params[prop];
    metaDescription = metaDescription.replace(`:${prop}`, `${value}`);
  });

  return `${metaDescription}${ paginationText ? `${paginationText}.` : paginationText}`;
};

const getSRPMetaDescription = (params, searchCount, makeModel, partyDetails, pageId, isSimilarBoatsPage) => {
  let description;
  let paginationText = '';
  let mainParamsText = '';
  let locationText;
  let sellerText = '';
  let boatsForSaleText = 'boats for sale';
  let addBoatsForSaleText = true;
  const defaultParams = getDefaultParams(params, pageId);
  const activeParams = getActiveParams(defaultParams, pageId);
  const arrayActiveValues = filter(activeParams, (value) => !isEmpty(value));
  const { page, pageSize } = defaultParams;
  paginationText += getPaginationText(page, pageSize, searchCount);
  paginationText = paginationText ? `${paginationText}.` : paginationText;

  if (isEmpty(arrayActiveValues)) { //activeParams is completely empty, it means we are at the SRP landing (Special case)
    description = 'Find new and used boats for sale on Boat Trader. Huge range of used private and dealer boats for sale near you.';
  }
  else if (size(arrayActiveValues) === 1 && activeParams.page) { //the only active param is the pagination, ite means we are at the SRP landing pagination (Special case)
    description = 'Find new and used boats for sale in your area & across the world on Boat Trader. Offering the best selection of boats to choose from.' + paginationText;
  }
  else { //Normal cases
    if (activeParams.condition) {
      mainParamsText += activeParams.condition;
    }

    let make;
    let displayMakeModel;
    if (activeParams.makeModel) {
      const arrayMakes = keys(activeParams.makeModel);
      make = arrayMakes[0];
      displayMakeModel = makeModel ? makeModel : capitalize(unhyphenateUrlComponents(unslugify(make)));
      const arrayModels = activeParams.makeModel[make];
      const model = arrayModels[0];
      if (model) {
        displayMakeModel = makeModel + ' ' + capitalizeEachWord(unhyphenateUrlComponents(unslugify(model)));
      } else if (activeParams.modelRange) {
        displayMakeModel = makeModel + ' ' + capitalizeEachWord(unhyphenateUrlComponents(unslugify(activeParams.modelRange)));
      } else if (params.seoMakeInfo && params.seoMakeInfo.seoMakeName) {
        displayMakeModel = params.seoMakeInfo.seoMakeName;
      }
      mainParamsText += ' ' + displayMakeModel;
    }

    if (activeParams.multiFacetedBoatTypeClass && !isEmpty(activeParams.multiFacetedBoatTypeClass)) {
      const boatType = keys(activeParams.multiFacetedBoatTypeClass)[0];
      const boatClass = activeParams.multiFacetedBoatTypeClass[boatType][0];
      let typeClassText;
      if (boatClass !== handleLegacyAllType(boatType, 'all')) {
        typeClassText = get(getClass(boatClass), 'name');
      }
      else {
        typeClassText = get(getType(boatType), 'name');
      }

      mainParamsText += ' ' + typeClassText.toLocaleLowerCase();

      if (boatType.toLocaleLowerCase() === 'sail') {
        boatsForSaleText = 'sailboats for sale';
        if (boatClass.toLocaleLowerCase() === 'sail-all') {
          mainParamsText = '';
        }
      }
    }
    else if (activeParams.group) {
      let cleanGroup = capitalizeEachWord(unhyphenateUrlComponents(unslugify(activeParams.group)));
      if (cleanGroup.includes('For Sale')){
        cleanGroup = cleanGroup.replace('For Sale', 'for sale');
        addBoatsForSaleText = false;
      }
      mainParamsText += ' ' + cleanGroup;
    }

    // Only one indexable fuel on description
    if (activeParams.fuelType && activeParams.fuelType.length === 1) {
      if (isFuelIndexable(activeParams.fuelType[0])) {
        mainParamsText += ' ' + activeParams.fuelType[0];
      }
    }

    // Only one indexable hullMaterial on description
    if (activeParams.hullMaterial && activeParams.hullMaterial.length === 1) {
      mainParamsText += ' ' + activeParams.hullMaterial[0];
    }

    if (mainParamsText.trim().toLocaleLowerCase().endsWith('boat') || mainParamsText.trim().toLocaleLowerCase().endsWith('boats')
     || mainParamsText.trim().toLocaleLowerCase().endsWith('yacht') || mainParamsText.trim().toLocaleLowerCase().endsWith('yachts')
     || mainParamsText.trim().toLocaleLowerCase().endsWith('ships')) {
      boatsForSaleText = 'for sale';
    }

    if (activeParams.zip) {
      locationText = activeParams.zip;
    }
    else if (!isEmpty(activeParams.city)) {
      locationText = capitalizeEachWord(unslugify(activeParams.city[0], '-', ' '));
    }
    else if (activeParams.state) {
      locationText = get(getStateByCode(activeParams.state), 'name', '');
    }

    if (activeParams.forSale) {
      sellerText += ` by ${activeParams.forSale}`;
    }

    let findBoatsForSaleText = locationText ? 'Find new and used boats for sale' : 'Find boats for sale';
    findBoatsForSaleText = boatsForSaleText.includes('sailboats') ? findBoatsForSaleText.replace('boats', 'sailboats') : findBoatsForSaleText;

    const listingCount = searchCount > 9 && !isSimilarBoatsPage && (activeParams.makeModel || activeParams.city || activeParams.state || activeParams.zip) ? `${searchCount} ` : '';


    const mainText = addBoatsForSaleText ?
      'Find ' +  listingCount + mainParamsText.trim() + ' ' + boatsForSaleText :
      'Find ' +  mainParamsText.trim();

    let descriptionPart1 = mainParamsText.trim() ? mainText : findBoatsForSaleText;
    let descriptionPart2 = locationText ? ' in ' + locationText : ' near you';
    let descriptionPart3 = `${sellerText}, including boat prices, photos, and more.`;
    let descriptionPart4;
    if (locationText && !activeParams.condition && !(activeParams.makeModel || makeModel) && !params.classes) {
      if (activeParams.forSale) {
        descriptionPart4 = ' Find your boat at Boat Trader!';
      } else {
        descriptionPart4 = ' For sale by owner, boat dealers and manufacturers - find your boat at Boat Trader!';
      }
    }
    else if (size(arrayActiveValues) === 2 && activeParams.state && activeParams.makeModel) {
      if (makeModel && (includes(makeModel.toLocaleLowerCase(), 'boat') || includes(makeModel.toLocaleLowerCase(), 'yacht') || includes(makeModel.toLocaleLowerCase(), 'ship'))) {
        descriptionPart4 = ' Locate ' + makeModel + ' dealers in ' + activeParams.state + ' and find your boat at Boat Trader!';
      } else {
        descriptionPart4 = ' Locate ' + (makeModel ? makeModel : capitalizeEachWord(unhyphenateUrlComponents(unslugify(make)))) + ' boat dealers in ' + activeParams.state + ' and find your boat at Boat Trader!';
      }
    }
    else if ((activeParams.condition || params.classes || activeParams.state || activeParams.zip) && (activeParams.makeModel || makeModel)) {
      if (makeModel && (includes(makeModel.toLocaleLowerCase(), 'boat') || includes(makeModel.toLocaleLowerCase(), 'yacht') || includes(makeModel.toLocaleLowerCase(), 'ship'))) {
        descriptionPart4 = ' Locate ' + makeModel + ' at Boat Trader!';
      } else {
        descriptionPart4 = ' Locate ' + (makeModel ? makeModel : capitalizeEachWord(unhyphenateUrlComponents(unslugify(make)))) + ' boats at Boat Trader!';
      }
    }
    else if ((activeParams.makeModel || makeModel) && !locationText) {
      if (makeModel && (includes(makeModel.toLocaleLowerCase(), 'boat') || includes(makeModel.toLocaleLowerCase(), 'yacht') || includes(makeModel.toLocaleLowerCase(), 'ship'))) {
        descriptionPart4 = ' Locate ' + makeModel + ' dealers and find your boat at Boat Trader!';
      } else {
        descriptionPart4 = ' Locate ' + (makeModel ? makeModel : capitalizeEachWord(unhyphenateUrlComponents(unslugify(make)))) + ' boat dealers and find your boat at Boat Trader!';
      }
    }
    else if (activeParams.hullMaterial && activeParams.hullMaterial.length === 1) {
      descriptionPart4 = ` Locate boat dealers and find your ${capitalizeEachWord(unhyphenateUrlComponents(unslugify(activeParams.hullMaterial[0])))} boat at Boat Trader!`;
    }
    else {
      descriptionPart4 = ' Locate boat dealers and find your boat at Boat Trader!';
    }

    if (activeParams.ownerId && partyDetails) {
      descriptionPart1 = `View details and boats for sale by ${partyDetails.name}`;
      if (pageId === ENGINES_ID){
        descriptionPart1 = `View details, boat motors & engines for sale by ${partyDetails.name}`;
      }
      descriptionPart2 = '';
      descriptionPart3 = '';
      if (partyDetails.address) {
        if (partyDetails.address.city) {
          descriptionPart2 = `, located in ${partyDetails.address.city}`;
        }
        if (partyDetails.address.countrySubDivisionCode) {
          const state = get(getStateByCode(partyDetails.address.countrySubDivisionCode), 'name');
          if (state) {
            descriptionPart3 = descriptionPart2 ? `, ${state}` : `, located in ${state}`;
          }
        }
      }
      descriptionPart4 = '. Get in contact for more information about the boats, services & company.';
      if (pageId === ENGINES_ID) {
        descriptionPart4 = '. Get in contact for more information about the boat motors & engines, services & company.';
      }
    }

    description = descriptionPart1 + descriptionPart2 + descriptionPart3 + descriptionPart4 + paginationText;
  }

  if (activeParams.priceRevisedSince) {
    let tex = description.split('for sale');
    description = `${tex[0]}with price drops for sale${tex[1]}`;
  }

  return description;
};

const getBDPMetaTitle = (boatData) => {
  let title = getBDPHeaderAndMetaTitleCommon(boatData);
  title += ' - Boat Trader';
  return title;
};

// Test for BT-137
export const temporaryValidClass = (boatClass) => {
  return ['Pontoon Boats', 'Jon Boats', 'Center Console Boats'].includes(boatClass);
};

const descriptionClassName = (className) => {
  const classMap = {
    'Pontoon Boats': 'pontoon boat'
  };
  return classMap[className];
};

const getBoatClassMetaTitle = (className) => temporaryValidClass(className) ? `${className} - Boat Type - Boat Trader` : 'Boat Trader';

const getBoatClassMetaDescription = (className) => {
  const boatClassDescription = {
    'Pontoon Boats': `Who needs a yacht when you can have a ${descriptionClassName(className)}?! The ultimate triple threat -- perfect for cruising, relaxing and partying, while also being speedy enough to tow skiers, tubers, and wakeboarders.`,
    'Jon Boats': 'Attention, hunters and fishers on a budget! If you\'re searching for a tough, adaptable, and wallet-friendly fishing boat, look no further than the trusty Jon boat.',
    'Center Console Boats': 'The design is ideal for many types of fishing boats, works well for family time spent on the water, and is flexible enough that a center console can also be used for activities like day-cruising, watersports, and other waterborne adventures.'
  };
  return boatClassDescription[className] || 'Boat Trader';
};

const getBoatClassCanonical = (boatClassParams) => {
  const host = process.env.REACT_APP_HOST;
  const { boatClass, displayName } = boatClassParams;
  return temporaryValidClass(displayName) ? `${host}/boat-types/${boatClass}/` : host;
};

const getServiceMetaTitle = (service) => `${service} - Boat Trader`;

const getOtherServiceMetaDescription = (pageType) => `${resourceConfigs[pageType].upperTitle} on Boat Trader.`;

const getOtherServiceURL = (pageType) => `${process.env.REACT_APP_HOST}${resourceConfigs[pageType].path}`;

export const getPaginationText = (page, pageSize, searchCount, delimiter = '') => {
  return (page && page !== '1') ? `${delimiter} ${page} of ${Math.ceil(searchCount / pageSize)} pages` : '';
};

const getSRPMetaTitle = (params, searchCount, make, partyDetails, pageType) => {
  const { page, pageSize } = ['BoatDealers', ENGINES_ID, 'DealerGallery'].includes(pageType)
    ? params
    : getDefaultParams(params, pageType);
  let title = '';
  if ( pageType === 'BoatDealers') {
    title = getDealersSRPHeader(params);
  } else if ([ENGINES_ID, 'DealerGallery'].includes(pageType)) {
    title = getEnginesSRPHeader(params, partyDetails);
    return reject([getPaginationText( page, pageSize, searchCount, '').trim(), title, 'Boat Trader'], isEmpty).join(' - ');
  } else {
    title = getSRPHeaderAndMetaTitleCommon(params, false, make, partyDetails);
  }

  return `${title}${getPaginationText( page, pageSize, searchCount, ' -')} - Boat Trader`;
};

const getBDPHeaderAndMetaTitleCommon = (boatData) => {
  let returnTitle;
  let locationText = '';
  let listingCondition = capitalizeEachWord(get(boatData, 'condition', ''));
  const listingTitle = getTitle(boatData).trim();
  const listingLocation = get(boatData, 'location.address.city', '');
  const listingZip = get(boatData, 'location.address.zip', '');

  if (listingZip){
    if (listingLocation) {
      locationText = listingZip + ' ' + listingLocation;
    } else {
      locationText = listingZip;
    }
  } else if (listingLocation) {
    locationText = listingLocation;
  }

  if (!isEmpty(locationText)){
    returnTitle = listingCondition + ' ' + listingTitle + ', ' + locationText;
  } else {
    if (!isEmpty(listingTitle)){
      returnTitle = listingCondition + ' ' + listingTitle;
    } else {
      returnTitle = listingCondition;
    }
  }

  return returnTitle;
};


const getSRPHeaderAndMetaTitleCommonPieces = (params, h2Header, makeModel, partyDetails) => {
  let returnText;
  let boatsForSaleText = 'Boats for sale';
  let addBoatsForSaleText = true;
  let mainParamsText = '';
  let locationText;
  let boatsOrSailboats = 'Boats';
  let boatType, boatTypeName;
  let boatClass, boatClassName;
  let expertReviewsTitle;
  const activeParams = getActiveParams(getDefaultParams(params));
  const isElectric = !!(activeParams.fuelType && activeParams.fuelType.find(fuel => fuel.match('electric')));
  if (activeParams.multiFacetedBoatTypeClass && !isEmpty(activeParams.multiFacetedBoatTypeClass)) {
    boatType = keys(activeParams.multiFacetedBoatTypeClass)[0];
    boatTypeName = get(getType(boatType), 'name');
    boatClass = activeParams.multiFacetedBoatTypeClass[boatType][0];
    boatClassName = get(getClass(boatClass), 'name');
    if (boatClass !== handleLegacyAllType(boatType, 'all')) {
      if (boatClassName === 'Other'){
        if (boatType === 'power'){
          mainParamsText = capitalize(boatClassName) + ' ' +  capitalize(boatType);
        } else {
          mainParamsText = capitalize(boatClassName);
        }
      } else {
        mainParamsText = boatClassName;
      }
    } else {
      mainParamsText = boatTypeName;
    }

    if (boatType.toLocaleLowerCase() === 'sail') {
      boatTypeName = '';
      boatsOrSailboats = 'Sailboats';
      boatsForSaleText = 'Sailboats for sale';
      if (boatClass.toLocaleLowerCase() === 'sail-all') {
        mainParamsText = '';
      }
    }

    if (boatType.toLocaleLowerCase() === 'small' && boatClass.toLocaleLowerCase() === 'small-all') {
      mainParamsText = capitalizeEachWord(boatType);
    }
  }
  else if (activeParams.group) {
    mainParamsText = capitalizeEachWord(unhyphenateUrlComponents(unslugify(activeParams.group)));
    if (mainParamsText.includes('For Sale')){
      mainParamsText = mainParamsText.replace('For Sale', 'for sale');
      addBoatsForSaleText = false;
    }
  }

  if (activeParams.makeModel) {
    makeModel = makeModel ? makeModel : '';
    const arrayMakes = keys(activeParams.makeModel);
    const make = arrayMakes[0];
    const arrayModels = activeParams.makeModel[make];
    const model = arrayModels[0];
    if (model) {
      makeModel = makeModel + ' ' + capitalizeEachWord(unhyphenateUrlComponents(unslugify(model)));
    } else if (activeParams.modelRange) {
      makeModel = makeModel + ' ' + capitalizeEachWord(unhyphenateUrlComponents(unslugify(activeParams.modelRange)));
    } else if (params.seoMakeInfo && params.seoMakeInfo.seoMakeName) {
      makeModel = params.seoMakeInfo.seoMakeName;
    }
    if (make === 'other'){
      mainParamsText = 'Other Make';
    } else {
      mainParamsText = makeModel + ' ' + mainParamsText;
    }
  }

  if (mainParamsText.trim().toLocaleLowerCase().endsWith('boat') || mainParamsText.trim().toLocaleLowerCase().endsWith('boats')
     || mainParamsText.trim().toLocaleLowerCase().endsWith('yacht') || mainParamsText.trim().toLocaleLowerCase().endsWith('yachts')
     || mainParamsText.trim().toLocaleLowerCase().endsWith('ships')) {
    boatsForSaleText = 'for sale';
  }

  if (activeParams.condition) {
    mainParamsText = capitalize(activeParams.condition) + ' ' + mainParamsText.trim();
  }

  if (activeParams.ownerId && partyDetails) {
    mainParamsText = partyDetails.name;
    if (partyDetails.address && partyDetails.address.city) {
      mainParamsText += `, ${partyDetails.address.city}`;
    }
    boatsForSaleText = '';
  }

  if (isElectric) {
    mainParamsText  += ' Electric ';
  }

  if (activeParams.fuelType && activeParams.fuelType.length === 1 ){
    if (activeParams.fuelType[0] === 'other'){
      mainParamsText = 'Other Fuel type';
    }
  }

  if (activeParams.engine && activeParams.engine === 'other'){
    mainParamsText = 'Other Engine type';
  }

  if (mainParamsText) {
    if (boatsForSaleText.includes('Sailboat')) {
      returnText = `${mainParamsText.trim()} ${boatsForSaleText}`;
    } else {
      if (boatsForSaleText) {
        returnText = addBoatsForSaleText ? `${mainParamsText.trim()} ${boatsForSaleText.toLowerCase()}` : mainParamsText.trim();
      } else {
        returnText = mainParamsText.trim();
      }
    }
  } else {
    returnText = boatsForSaleText;
  }

  if (activeParams.zip) {
    locationText = activeParams.zip;
  }
  else if (!isEmpty(activeParams.city)) {
    locationText = capitalizeEachWord(unslugify(activeParams.city[0], '-', ' '));
  }
  else if (activeParams.state) {
    locationText = get(getStateByCode(activeParams.state), 'name', '');
  }

  if (locationText) {
    returnText += ' in ' + locationText;
  }

  if (activeParams.forSale && activeParams.forSale !== 'all') {
    returnText += ` by ${activeParams.forSale}`;
  }

  if (h2Header && returnText !== 'Boats for sale') {
    returnText = returnText.replace(' for sale', '');
  }

  if (isElectric && returnText === 'Electric boats for sale') {
    returnText = returnText.replace(' for sale', '');
  }

  if (activeParams.priceRevisedSince) {
    let tex = returnText.split('for sale');
    returnText = `${tex[0]}with price drops for sale${tex[1]}`;
  }

  if (activeParams.hullMaterial && activeParams.hullMaterial.length === 1) {
    if (unslugify(activeParams.hullMaterial[0], '-', ' ') === 'other'){
      returnText = 'Other Hull type boats';
    } else {
      returnText = `${capitalizeEachWord(unslugify(activeParams.hullMaterial[0], '-', ' '))} boats`;
    }
  }

  expertReviewsTitle = `Expert ${returnText.replace('for sale', '').trim()} Reviews`;

  return {
    returnText,
    boatType,
    boatTypeName,
    boatClass,
    boatClassName,
    boatsOrSailboats,
    expertReviewsTitle
  };
};

export const memoizedGetSRPHeaderAndMetaTitleCommonPieces = memoize(getSRPHeaderAndMetaTitleCommonPieces, multiParamMemoizeResolver);


const getSRPHeaderAndMetaTitleCommon = (params, h2Header, makeModel, partyDetails) => {
  const { returnText } = memoizedGetSRPHeaderAndMetaTitleCommonPieces(params, h2Header, makeModel, partyDetails);
  return returnText;
};

/**
 * This function generates a pattern in the form :varName1 :varName2 :varName3 to be replaced later
 * @param keywordList
 * @returns {string|{pattern: string, hasLocation: boolean}}
 */
export const prepareBasicPattern = (keywordList) => {
  if (!Array.isArray(keywordList) || !keywordList.length) {
    return '';
  }
  let dynamicPatternName = '';
  let blank = '';
  for (let key of keywordList) {
    dynamicPatternName = `${dynamicPatternName}${blank}:${key}`;
    blank = ' ';
  }
  return dynamicPatternName;
};

/**
 * Function to generate dynamically a header with the proper structure.
 * @param params: {[key:string]: string|object} with the data
 * @param patternTextFinder: () => string to find the proper text pattern for the data.
 *
 * @param defaultTexts: {[key:string]: string} key-value structure where we associate a key with a text pattern <some-text> :somePatternVariable. Used for default pattern mechanism
 * @param defaultKeys: string:[] valid keys to generate the header
 * @param patternFilterToFormat: {[key:string]: function} key defined functions to format data
 * @returns {string} the header
 */
export const getProperDynamicText = (params, patternTextFinder, patternFilterToFormat = {}, defaultKeys = [], defaultTexts = {}, greedy = false) => {
  const activeFilters = Object.keys(params).filter(key => params[key] && defaultKeys.includes(key));

  // If we don't get a function to find the key, we just join the keys
  let patternText = typeof patternTextFinder === 'function' ? patternTextFinder(activeFilters) : defaultTexts[activeFilters.join('')] || '';

  // for each pair of key-value we format value and replace the ':pattern' string in the text
  Object.entries(activeFilters).forEach(key => {
    const prop = key[1];
    const formatterFn = typeof patternFilterToFormat[prop] === 'function' ? patternFilterToFormat[prop] : null;
    let value = formatterFn ? formatterFn(params[prop], patternText, activeFilters, params) : params[prop];
    patternText = greedy ? patternText.replace(new RegExp(`:${prop}`, 'g'), `${value}`) : patternText.replace(`:${prop}`, `${value}`);
  });

  return patternText;
};

const getBDPCanonical = (boatData) => {
  let canonicalUrl;
  canonicalUrl = getBoatUrl(boatData, true);
  return canonicalUrl;
};

export const getDealerSRPCanonical = (params) => {
  let canonicalUrlParams = {};
  const activeFilter = Object.keys(params).filter(
    key => params[key] && ['make','countrySubDivisionCode','city','postalCode'].includes(key)
  );

  Object.entries(activeFilter).forEach( key => {
    const prop = key[1];
    canonicalUrlParams = { ...canonicalUrlParams, [prop]: params[prop] };
  });

  if (params.page !== '1') {
    canonicalUrlParams = { ...canonicalUrlParams, page: params.page };
  }
  const code = canonicalUrlParams.countrySubDivisionCode;
  if (code) {
    canonicalUrlParams.countrySubDivisionCode = getSubdivisionCode(code) || code;
  }
  return process.env.REACT_APP_HOST + getDealerSearchUrl({...canonicalUrlParams});
};

export const getEnginesSRPCanonical = (params) => {
  let canonicalUrlParams = {};
  const activeFilter = Object.keys(params).filter(
    key => params[key] && ['condition','engine','make','model','makeModel','powerHp','fuelType','state','city','zip'].includes(key)
  );

  Object.entries(activeFilter).forEach( key => {
    const prop = key[1];
    canonicalUrlParams = { ...canonicalUrlParams, [prop]: params[prop] };
  });

  if (params.page !== '1') {
    canonicalUrlParams = { ...canonicalUrlParams, page: params.page };
  }

  return process.env.REACT_APP_HOST + generateSearchPath(undefined, {...canonicalUrlParams}, false, ENGINES_ID);
};

const getSRPCanonical = (params) => {
  let canonicalUrl;
  let canonicalUrlParams;
  let locationParams;
  const defaultParams = getDefaultParams(params);
  const activeParams = getActiveParams(defaultParams);

  if (activeParams.zip) {
    locationParams = { ...locationParams, zip: activeParams.zip };
  }
  if (activeParams.city && activeParams.city.length === 1) {
    locationParams = { ...locationParams, city: activeParams.city };
  }
  if (activeParams.state) {
    locationParams = { ...locationParams, state: activeParams.state };
  }

  if (activeParams.makeModel && locationParams) { // Make + Model + Location
    canonicalUrlParams = { ...locationParams, makeModel: activeParams.makeModel };
  }
  else if (activeParams.multiFacetedBoatTypeClass && !isEmpty(activeParams.multiFacetedBoatTypeClass) && locationParams) { // Type + Class + Location
    canonicalUrlParams = { ...locationParams, multiFacetedBoatTypeClass: activeParams.multiFacetedBoatTypeClass };
  }
  else if (activeParams.group && locationParams) { // Group of classes + Location
    canonicalUrlParams = { ...locationParams, group: activeParams.group };
  }
  else if (locationParams) { // Location
    canonicalUrlParams = { ...locationParams };
  }
  else if (activeParams.makeModel) { // Make + Model
    canonicalUrlParams = { makeModel: activeParams.makeModel };
  }
  else if (activeParams.multiFacetedBoatTypeClass && !isEmpty(activeParams.multiFacetedBoatTypeClass)) { // Type + Class
    canonicalUrlParams = { multiFacetedBoatTypeClass: activeParams.multiFacetedBoatTypeClass };
  }
  else if (activeParams.group) { // Group of classes
    canonicalUrlParams = { group: activeParams.group };
  }
  else if (activeParams.ownerId) { // Dealer gallery page
    canonicalUrlParams = { ownerId: activeParams.ownerId };
  }

  if (activeParams.forSale) {
    canonicalUrlParams = { ...canonicalUrlParams, forSale: activeParams.forSale};
  }

  if (activeParams.fuelType && activeParams.fuelType.length === 1) {
    canonicalUrlParams = { ...canonicalUrlParams, fuelType: activeParams.fuelType[0]};
  }

  if (activeParams.hullMaterial && activeParams.hullMaterial.length === 1) {
    canonicalUrlParams = { ...canonicalUrlParams, hullMaterial: activeParams.hullMaterial[0]};
  }

  if (defaultParams.page !== '1') {
    canonicalUrlParams = { ...canonicalUrlParams, page: activeParams.page };
  }

  canonicalUrl = process.env.REACT_APP_HOST + generateSearchPath(canonicalUrlParams, getDefaultParams({}), false);
  return canonicalUrl;
};

const isFuelIndexable = (fuelType, searchPage) => searchPage === ENGINES_ID ? true : INDEXABLE_FUELS.some(fuel => fuel.match(fuelType));

export const getSRPNoIndex = (url, pageType) => {
  if (pageType === ENGINES_ID) {
    return getSRPNoFollow(url, pageType);
  }
  if (pageType === BRANDS_ID) {
    return getOemSrpNoIndex(url, pageType);
  }
  if (isBrokerDealerSRP(url, pageType)) {
    return false;
  }
  return getSRPNoFollow(url, pageType);
};

export const getSRPNoFollow = (url, pageType) => {
  // TODO: Fix for trailers (and other possible pages)
  const searchPage = pageType === ENGINES_ID ? ENGINES_ID : '';
  const urlParams = parseSearchAppParams(url, searchPage);
  const activeParams = getActiveParams(urlParams, searchPage);
  const INDEXABLE_PARAMS = searchPage === ENGINES_ID ? INDEXABLE_ENGINES_FACETS : INDEXABLE_FACETS;
  return Object.entries(activeParams)
    .reduce((noFollow, [paramKey, paramValue]) => {
      if (noFollow) {
        return noFollow;
      }
      if (pageType !== ENGINES_ID && paramKey === 'ownerId' && Object.keys(activeParams).filter((k) => !isEmpty(activeParams[k])).length === 1) {
        return false;
      }
      if (!includes(INDEXABLE_PARAMS, paramKey)) {
        return true;
      }
      switch (paramKey) {
      case 'fuelType':
        return !isFuelIndexable(paramValue, searchPage);
      case 'multiFacetedBoatTypeClass':
      case 'makeModel':
      case 'city':
      case 'hullMaterial':
        return !isSingleFaceted(paramValue);
      }
    }, false);
};

export const entriesToString = (entries) => {
  const entriesString = Object.entries(entries)
    .map(([key, value]) => {
      if (value) {
        return key;
      }
    })
    .filter((entry) => !!entry)
    .join(' ');
  return entriesString || null;
};

export const getItemListSchema = (params, search, url) => {
  const pageType = getPageTypeFromUrl(url);
  const defaultParams = getDefaultParams(params);
  if (pageType === 'SearchResults' && get(search, 'count', 0) > 0 && search.records){
    const numberOfItems = search.count;
    const results = search.records.map((record, index) => {
      let image = '';
      if (first(record.media)){
        image = first(record.media).url;
      }
      return ({
        '@type': 'ListItem',
        position: index,
        url: record.portalLink,
        image: image,
        name: getTitle(record)
      });
    });
    return ({
      '@context': 'https://schema.org',
      '@type': 'ItemList',
      'url': process.env.REACT_APP_HOST + generateSearchPath({}, defaultParams, false),
      'numberOfItems': numberOfItems,
      'itemListOrder': 'https://schema.org/ItemListUnordered',
      'itemListElement': results
    });
  }
  return '';
};

export const getAggregateOfferSchema = (search, url, makeModel, SRPHeader, metaDescription, minPrice, maxPrice ) => {
  const pageType = getPageTypeFromUrl(url);
  if ( minPrice === 0 && maxPrice === 0){
    return '';
  }
  if (pageType === 'SearchResults' && get(search, 'count', 0) > 0 && search.records){
    let brand = {};
    if (makeModel){
      brand = {
        'brand': {
          '@type': 'brand',
          'name': makeModel
        }
      };
    }
    const results = {
      '@context': 'https://schema.org',
      '@type': 'Vehicle',
      'mainEntityOfPage': process.env.REACT_APP_HOST + url,
      'name': SRPHeader,
      'description': metaDescription,
      'offers': {
        '@type': 'AggregateOffer',
        'priceCurrency': 'USD',
        'lowPrice': minPrice.toFixed(0),
        'highPrice': maxPrice.toFixed(0),
        'offerCount': get(search, 'count', 0)
      }
    };
    return Object.assign(results,brand);
  }
  return '';
};

export const getFAQSchema = (questions) => {
  return questions.reduce((acc, { question, answer }) => {
    acc.mainEntity.push({
      '@type': 'Question',
      name: question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: answer
      }
    });
    return acc;
  }, {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity: []
  });
};

export const getProductSchema = (listing) => {
  let firstImage = first(listing.media);
  const description = get(listing, 'description', '');
  const htmlTagSanitizerRegex = /(<([^>]+)>)/ig;
  const sanitizedDescription = description.replace(htmlTagSanitizerRegex, '');

  if (has(listing.price, 'type') && firstImage) {
    return ({
      '@context': 'https://schema.org',
      '@type': 'Product',
      name: getTitle(listing),
      image: firstImage.url,
      description: sanitizedDescription,
      url: listing.portalLink,
      brand: {
        '@type': 'Organization',
        name: get(listing, 'make', ''),
      },
      offers: {
        '@type': 'Offer',
        availability: 'https://schema.org/InStock',
        priceCurrency: 'USD',
        price: get(listing.price.type, 'amount.USD', ''),
        seller: {
          '@type': getSellerEntity(listing),
          name: get(listing, 'contact.name')
        },
        url: listing.portalLink
      },
      weight: getWeightsMap(listing),
      itemCondition: getListingCondition(listing),
      productID: listing.id,
    });
  }
};

const getListingCondition = (listing) => {
  switch (listing.condition) {
  case 'used':
    return 'UsedCondition';
  case 'new':
  default:
    return 'NewCondition';
  }
};

const getWeightsMap = (listing) => {
  const weights = get(listing, 'specifications.weights');
  let weightList = [];
  if (has(weights, 'dry.lb')) {
    weightList.push({
      '@type': 'QuantitativeValue',
      unitText: 'lb',
      value: weights.dry.lb
    });
  }
  if (has(weights, 'dry.kg')) {
    weightList.push({
      '@type': 'QuantitativeValue',
      unitText: 'kg',
      value: weights.dry.kg
    });
  }
  return weightList;
};

const getSellerEntity = (listing) => {
  return isFSBOContact(listing.contact) ? 'Person' : 'Organization';
};

export const getBreadCrumbSchema = (items) => {
  return ({
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement:
        items.map((element, index) => {
          return generateBreadCrumbSchema(index, element.link, element.title);
        })
  });
};

const generateBreadCrumbSchema = (index, link, title) => {
  return {
    '@type': 'ListItem',
    position: index,
    item: {
      '@id': process.env.REACT_APP_HOST + link,
      name: title
    }
  };
};

const isBrokerDealerSRP = (url, pageType) => {
  const urlParams = parseSearchAppParams(url, pageType);
  const activeParams = getActiveParams(urlParams);
  const cleanedParams = removeEmptyObjects(activeParams);
  return cleanedParams.ownerId && Object.keys(cleanedParams).length === 1;
};

const removeEmptyObjects = (paramsObject) => {
  Object.keys(paramsObject).forEach(param => {
    if (isEmpty(paramsObject[param])) {
      delete paramsObject[param];
    }
  });
  return paramsObject;
};

export const getWordsmithFAQSchema = (wordsmithContent) => {
  if (wordsmithContent) {
    const splittedContent = wordsmithContent.split('<script type="application/ld+json">');
    if (splittedContent.length > 1) {
      return {
        narrative: splittedContent[0].replace(/\\/g, ''),
        FAQSchema: splittedContent[1].replace('</script>','').replace(/\\\\/g, '\\')
      };
    }
    return {
      narrative: splittedContent[0],
      FAQSchema: ''
    };
  }
  return {
    narrative: '',
    FAQSchema: ''
  };
};

export const getWebsiteSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'WebSite',
    'name': 'Boat Trader',
    'url': process.env.REACT_APP_HOST,
  };
};

export const getOrganizationSchema = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    'name': 'Boat Trader',
    'url': process.env.REACT_APP_HOST,
    'sameAs': [
      'https://www.facebook.com/BoatTrader/',
      'https://twitter.com/boattrader',
      'https://www.instagram.com/boattrader/',
      'https://www.youtube.com/boattrader'
    ],
    'logo': `${process.env.REACT_APP_CDN_URL}/img/boattrader-logo.png`
  };
};

export const getOemSearchResultsMetaTitle = (oemDetails) => {
  return `${oemDetails.name} | ${oemDetails.headline} - Boat Trader`;
};

export const getOemSrpNoIndex = (url, pageType) => {
  const urlParams = parseSearchAppParams(url, pageType);
  const activeParams = getActiveParams(urlParams, '');

  if (activeParams.multiFacetedBoatTypeClass && isEmpty(activeParams.multiFacetedBoatTypeClass)) {
    delete activeParams.multiFacetedBoatTypeClass;
  }
  const oemParams = activeParams.oem || {};
  const selectedMakes = Object.keys(oemParams);
  const selectedMake = selectedMakes[0];
  if (Object.keys(activeParams).length === 1 && activeParams.oem && activeParams.oem[selectedMake].length === 0) {
    return false;
  }
  return true;
};

export const getOemSearchResultsMetaDescription = (oemDetails) => {
  return oemDetails?.intro ? oemDetails.intro.substring(0, oemDetails.intro.indexOf('.')) : '';
};
