import find from 'lodash/find';
import {hyphenateTrimmed} from './urlHelpers/listings';
import {normalizeString} from '@dmm/lib-common/lib/formatting';

export const typeAheadMatchesValue = (typeAheadText, value) => {
  const normalizedTypeAhead = normalizeString(typeAheadText).replace(/ /g, '').replace(/-/g,'');
  const normalizedValue = normalizeString(value).replace(/ /g, '').replace(/-/g,'');
  return value.toLowerCase().includes(typeAheadText.toLowerCase()) || normalizedValue.includes(normalizedTypeAhead);
};

export const compareNormalizations = (selectedItems, value) => {
  const slugTrim = hyphenateTrimmed(value);
  const normalized = normalizeString(hyphenateTrimmed(value));
  const res = find(selectedItems, (selectedItem) => {
    let normalizedSelectedItem = normalizeString(hyphenateTrimmed(selectedItem));
    return normalizedSelectedItem === normalized || normalizedSelectedItem === slugTrim;
  });
  return res;
};
