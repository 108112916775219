import React from 'react';
import { Breadcrumb } from '@dmm/lib-react-ui-components';
import RichSnippet from '../../components/RichSnippet';
import DefaultLayout from '../../layouts/Default';
import { BOAT_LOANS_RATES_URL } from '../../constants/BoatLoans';
import { getFAQSchema } from '../../utils/metaContentHelper';
import { faq } from './components/FrequentlyQuestionsSection/faqSchema';
import './styles.css';

const BoatLoansRates = (props) => {

  return (
    <>
      <RichSnippet schema={getFAQSchema(faq)} />
      <DefaultLayout {...props} pageType="BoatLoansRates" hideOverflow>
        <div className="breadcrumb-container">
          <Breadcrumb items={[{
            text: 'Home',
            url: '/'
          }, {
            text: 'Boat Loans Rates',
            url: BOAT_LOANS_RATES_URL,
          }]} />
        </div>

        <div className="main-section">
          <div className="boatloans-rates">
          </div>
        </div>
      </DefaultLayout>
    </>
  );
};

export default BoatLoansRates;
